<template>
    <mds-layout-grid id="grid">
      <mds-row align-horizontal="center">
        <mds-col
          class="product-list-col"
          :cols="12"
          :cols-at-s="4"
          :cols-at-l="4"
          :cols-at-xl="3"
        >
          <mds-card
            supplemental-content-first
            size="medium"
            class="product-list-card"
            title="DBRS Access Review Application"
            caption="User Access Review Application for Credit Tech"
          >
            <template v-slot:mds-card-action>
              <mds-button-container left-aligned>
                <mds-button
                  variation="secondary"
                  type="button"
                  class="product-list-btn"
                  text="Register Here"
                  @click="redirectToRegisterPage('/register')"
                />
                <mds-button
                  variation="primary"
                  type="button"
                  class="product-list-btn"
                  :href="redirectToCreditLogin()"
                  text="Login Here"
                />
              </mds-button-container>
            </template>
          </mds-card>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </template>
  
  <script>    
  import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid-vue3'
  import MdsCard from '@mds/card-vue3'
  import { MdsButton, MdsButtonContainer } from '@mds/button-vue3';
//   import ProductListMessages from '@/components/ProductList/i18n';
//   import products from '@/data/products.json';
  
  export default {
    name: 'LandingPage',
    components: {
      MdsLayoutGrid,
      MdsRow,
      MdsCol,
      MdsCard,
      MdsButton,
      MdsButtonContainer
    },
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
      redirectToRegisterPage(pathName) {
        this.$router.push(pathName);
      },
      redirectToCreditLogin() {
        const loginUrl = new URL(process.env.VUE_APP_LOGIN_URL);
        loginUrl.searchParams.append("targetUrl", process.env.VUE_APP_CR_URL+"/home");
        return loginUrl.href;
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .product-list-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .product-list-btn {
    margin-top: auto;
  }
  </style>
  
  <style lang="scss" module>
  :global(.product-list-card) {
    .mds-card__action {
      margin-top: auto;
    }
  }
  </style>
  