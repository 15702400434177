<template>
  <div class="hello">
    <div>
      <screen-designer
        ref="screenDesigner"
        :ui-schema="uiSchema"
        :data="uiData"
      >
      </screen-designer>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import ScreenDesigner from "@cr/screen-builder/src/components/screen-builder/ScreenDesigner.vue";
import EventBus from "@cr/screen-builder/src/eventbus";

export default {
  name: "HelloWorld",
  components: {
    ScreenDesigner,
  },
  data() {
    return {
      uiSchema: {
        type: "VerticalLayout",
        elements: [
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Section",
                customOptions: {
                  border: "primary",
                  title: "Repline Builder",
                  container: true,
                  bold: true,
                  collapsible: true,
                  expanded: true,
                },
                elements: [
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "button",
                        customOptions: {
                          variation: "primary",
                          icon: "open-new",
                          text: "Validate",
                        },
                        events: [
                          {
                            type: "click",
                            extra: "This is extra data",
                            name: "on-generate-pool-results-click",
                          },
                        ],
                        colspan: 2,
                      },
                      {
                        type: "button",
                        customOptions: {
                          variation: "secondary",
                          text: "Open Modal",
                        },
                        events: [
                          {
                            type: "click",
                            extra: "This is extra data",
                            name: "on-generate-pool-results-click-2",
                          },
                        ],
                        colspan: 2,
                      },
                      {
                        type: "button",
                        customOptions: {
                          variation: "flat",
                          text: "Open Dialog",
                        },
                        events: [
                          {
                            type: "click",
                            extra: "This is extra data",
                            name: "on-generate-pool-results-click-3",
                          },
                        ],
                        colspan: 1,
                      },
                      {
                        type: "link",
                        href: "/demo",
                        customOptions: {
                          text: "Default Link",
                          noVisitedStyles: true,
                          inTable: true,
                        },
                        colspan: 2,
                      },
                      {
                        type: "link",
                        href: "/demo",
                        customOptions: {
                          text: "Link with icon",
                          noVisitedStyles: true,
                          inTable: true,
                          icon: "import",
                        },
                        colspan: 2,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "button-container",
                        customOptions: {},
                        elements: [
                          {
                            type: "button",
                            customOptions: {
                              variation: "primary",
                              text: "Submit",
                            },
                            events: [
                              {
                                type: "click",
                                name: "on-submit",
                              },
                            ],
                          },
                          {
                            type: "button",
                            customOptions: {
                              variation: "secondary",
                              text: "Cancel",
                            },
                            events: [
                              {
                                type: "click",
                                name: "on-cancel",
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: "button-container",
                        customOptions: {
                          rightAligned: true,
                          requireVerticalSeperator: true,
                        },
                        elements: [
                          {
                            type: "button",
                            customOptions: {
                              icon: "save",
                              text: "Save Scenario",
                              variation: "flat",
                            },
                            events: [
                              {
                                type: "click",
                                name: "on-save",
                              },
                            ],
                          },
                          {
                            type: "button",
                            customOptions: {
                              icon: "download",
                              text: "Download",
                              variation: "flat",
                            },
                            events: [
                              {
                                type: "click",
                                name: "on-download",
                              },
                            ],
                          },
                          {
                            type: "button",
                            customOptions: {
                              iconRight: "caret-down",
                              text: "View Observation",
                              variation: "flat",
                            },
                            events: [
                              {
                                type: "click",
                                name: "on-view-observation",
                              },
                            ],
                          },
                          {
                            type: "button",
                            customOptions: {
                              icon: "open-new",
                              text: "Copy",
                              variation: "flat",
                            },
                            events: [
                              {
                                type: "click",
                                name: "on-copy",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "input",
                        dataType: "text",
                        key: "textInputValue",
                        customOptions: {
                          label: "Text Input",
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "text",
                        key: "textInputValue",
                        customOptions: {
                          label: "Text Input",
                          readonly: true,
                        },
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "input",
                        dataType: "number",
                        key: "numberInputValue",
                        min: 100,
                        max: 700,
                        customOptions: {
                          label: "Number Input",
                          allowEmpty: true,
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "number",
                        key: "numberROValue",
                        customOptions: {
                          label: "Number Input",
                          readonly: true,
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "number",
                        key: "numberROValue",
                        customOptions: {
                          label: "Number Input",
                          readonly: true,
                          textAlignment: "left",
                        },
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "input",
                        dataType: "percent",
                        key: "percentInputValue",
                        required: true,
                        min: 0.2,
                        max: 0.6,
                        customOptions: {
                          label: "Percent Input",
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "percent",
                        key: "percentROValue",
                        customOptions: {
                          label: "Percent Input",
                          readonly: true,
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "percent",
                        key: "percentROValue",
                        customOptions: {
                          label: "Percent Input",
                          readonly: true,
                          textAlignment: "left",
                        },
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "input",
                        dataType: "currency",
                        key: "currencyInputValue",
                        customOptions: {
                          label: "Currency Input",
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "currency",
                        key: "currencyROValue",
                        customOptions: {
                          label: "Currency Input",
                          readonly: true,
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "currency",
                        key: "currencyROValue",
                        customOptions: {
                          label: "Currency Input",
                          readonly: true,
                          textAlignment: "left",
                        },
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "input",
                        dataType: "date",
                        key: "dateInputValue",
                        customOptions: {
                          label: "Date Input",
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "date",
                        key: "dateROValue",
                        customOptions: {
                          label: "Date Input",
                          readonly: true,
                        },
                        colspan: 3,
                      },
                      {
                        type: "input",
                        dataType: "date",
                        key: "dateROValue",
                        customOptions: {
                          label: "Date Input",
                          dateFormat: "DD/MM/YYYY",
                          readonly: true,
                        },
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "textarea",
                        key: "textareaValue",
                        customOptions: {
                          label: "Tranche Name",
                          hiddenLabel: true,
                        },
                        colspan: 3,
                      },
                      // {
                      //   type: "datePicker",
                      //   key: "startDate",
                      //   customOptions: {
                      //     label: "Start Date",
                      //     disableDates: [
                      //       "2023-10-01T18:30:00.000Z"
                      //     ],
                      //     labels: {
                      //       day: "2-digit",
                      //       month: "long",
                      //       button: {
                      //         previous: "Previous Month",
                      //         next: "Next Month"
                      //       }
                      //     },
                      //     dateFormat: {
                      //       day: "2-digit",
                      //       month: "long"
                      //     },
                      //     highlightToday: true,
                      //     minMaxDates: {
                      //       min: new Date("2014-12-31T18:30:00.000Z"),
                      //       max: new Date("2025-09-14T18:30:00.000Z")
                      //     }
                      //   },
                      //   colspan: 2
                      // },
                      {
                        type: "timePicker",
                        key: "startTime",
                        customOptions: {
                          label: "Time",
                          placeholder: "Time",
                        },
                        colspan: 2,
                      },
                      {
                        type: "select",
                        key: "country",
                        customOptions: {
                          label: "Country",
                          hiddenLabel: true,
                          placeholder: "Select a Country",
                        },
                        required: true,
                        selectOptions: [
                          {
                            text: "India",
                            value: "ind",
                          },
                          {
                            text: "United States of America",
                            value: "usa",
                          },
                          {
                            text: "Canada",
                            value: "can",
                          },
                        ],
                        colspan: 2,
                      },
                      {
                        type: "searchfield",
                        key: "searchedValue",
                        customOptions: {
                          placeholder: "Search by Document ID and Title",
                          variation: "primary",
                          label: "Search",
                          size: "medium",
                          disabled: false,
                        },
                        colspan: 3,
                      },
                      {
                        type: "modal",
                        key: "toggleModal",
                        customOptions: {},
                        modalSchema: {
                          type: "Section",
                          customOptions: {
                            title: "Section With Container Bold Title",
                          },
                          elements: [
                            {
                              type: "VerticalLayout",
                              elements: [
                                {
                                  type: "input",
                                  dataType: "date",
                                  key: "dateInputValue",
                                  customOptions: {
                                    label: "Date Input",
                                  },
                                  colspan: 3,
                                },
                                {
                                  type: "input",
                                  dataType: "date",
                                  key: "dateROValue",
                                  customOptions: {
                                    label: "Date Input",
                                    readonly: true,
                                  },
                                  colspan: 3,
                                },
                                {
                                  type: "input",
                                  dataType: "date",
                                  key: "dateROValue",
                                  customOptions: {
                                    label: "Date Input",
                                    dateFormat: "DD/MM/YYYY",
                                    readonly: true,
                                  },
                                  colspan: 3,
                                },
                              ],
                            },
                          ],
                          actions: {
                            type: "button-container",
                            customOptions: {},
                            elements: [
                              {
                                type: "button",
                                customOptions: {
                                  variation: "primary",
                                  text: "Copy",
                                },
                                events: [
                                  {
                                    type: "click",
                                    name: "on-copy",
                                  },
                                ],
                              },
                              {
                                type: "button",
                                customOptions: {
                                  variation: "secondary",
                                  text: "Close",
                                },
                                events: [
                                  {
                                    type: "click",
                                    name: "on-close",
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      },
                      {
                        type: "dialog",
                        key: "toggleDialog",
                        customOptions: {
                          title: "Dialog Title",
                          text: "This is a dialog that offers a tertiary action.",
                        },
                        actionsLeft: {
                          type: "button",
                          customOptions: {
                            variation: "secondary",
                            text: "Don't Save",
                          },
                          events: [
                            {
                              type: "click",
                              name: "on-copy",
                            },
                          ],
                        },
                        actionsRight: {
                          type: "button-container",
                          customOptions: {},
                          elements: [
                            {
                              type: "button",
                              customOptions: {
                                variation: "secondary",
                                text: "Cancel",
                              },
                              events: [
                                {
                                  type: "click",
                                  name: "on-dialog-cancel",
                                },
                              ],
                            },
                            {
                              type: "button",
                              customOptions: {
                                variation: "primary",
                                text: "Save",
                              },
                              events: [
                                {
                                  type: "click",
                                  name: "on-dialog-save",
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "combobox",
                        key: "usState",
                        options: [
                          {
                            text: "Alabama",
                            value: "AL",
                          },
                          {
                            text: "Alaska",
                            value: "AK",
                          },
                          {
                            text: "American Samoa",
                            value: "AS",
                          },
                          {
                            text: "Arizona",
                            value: "AZ",
                          },
                          {
                            text: "Arkansas",
                            value: "AR",
                          },
                          {
                            text: "California",
                            value: "CA",
                          },
                          {
                            text: "Colorado",
                            value: "CO",
                          },
                          {
                            text: "Connecticut",
                            value: "CT",
                          },
                          {
                            text: "Delaware",
                            value: "DE",
                          },
                          {
                            text: "District Of Columbia",
                            value: "DC",
                          },
                          {
                            text: "Florida",
                            value: "FL",
                          },
                        ],
                        customOptions: {
                          label: "US States (Single Select ComboBox)",
                          placeholder: "Select a US state",
                        },
                        colspan: 3,
                      },
                      {
                        type: "combobox",
                        key: "usStateRequired",
                        options: [
                          {
                            text: "Alabama",
                            value: "AL",
                          },
                          {
                            text: "Alaska",
                            value: "AK",
                          },
                          {
                            text: "American Samoa",
                            value: "AS",
                          },
                          {
                            text: "Arizona",
                            value: "AZ",
                          },
                          {
                            text: "Arkansas",
                            value: "AR",
                          },
                          {
                            text: "California",
                            value: "CA",
                          },
                          {
                            text: "Colorado",
                            value: "CO",
                          },
                          {
                            text: "Connecticut",
                            value: "CT",
                          },
                          {
                            text: "Delaware",
                            value: "DE",
                          },
                          {
                            text: "District Of Columbia",
                            value: "DC",
                          },
                          {
                            text: "Florida",
                            value: "FL",
                          },
                        ],
                        required: true,
                        customOptions: {
                          label:
                            "US States (Single Select ComboBox with Required)",
                          placeholder: "Select a US state",
                          errorText: "Please select a US State",
                        },
                        colspan: 3,
                      },
                      {
                        type: "combobox",
                        key: "usStates",
                        options: [
                          {
                            text: "Alabama",
                            value: "AL",
                          },
                          {
                            text: "Alaska",
                            value: "AK",
                          },
                          {
                            text: "American Samoa",
                            value: "AS",
                          },
                          {
                            text: "Arizona",
                            value: "AZ",
                          },
                          {
                            text: "Arkansas",
                            value: "AR",
                          },
                          {
                            text: "California",
                            value: "CA",
                          },
                          {
                            text: "Colorado",
                            value: "CO",
                          },
                          {
                            text: "Connecticut",
                            value: "CT",
                          },
                          {
                            text: "Delaware",
                            value: "DE",
                          },
                          {
                            text: "District Of Columbia",
                            value: "DC",
                          },
                          {
                            text: "Florida",
                            value: "FL",
                          },
                        ],
                        customOptions: {
                          label: "US States (Multiple Select ComboBox)",
                          placeholder: "Select US state(s)",
                          multiple: true,
                        },
                        colspan: 3,
                      },
                      {
                        type: "combobox",
                        key: "operSys",
                        options: [
                          {
                            text: "Windows 10",
                            value: "windows-10",
                          },
                          {
                            text: "MacOS",
                            children: [
                              {
                                text: "Mojave",
                                value: "10.14",
                              },
                              {
                                text: "High Sierra",
                                value: "10.13",
                              },
                              {
                                text: "Sierra",
                                value: "10.12",
                              },
                              {
                                text: "El Capitan",
                                value: "10.11",
                              },
                              {
                                text: "Mavericks",
                                value: "10.10",
                              },
                            ],
                          },
                          {
                            text: "iOS",
                            children: [
                              {
                                text: "13",
                                value: "ios-13",
                              },
                              {
                                text: "12",
                                value: "ios-12",
                              },
                              {
                                text: "11",
                                value: "ios-11",
                              },
                              {
                                text: "10",
                                value: "ios-10",
                              },
                              {
                                text: "9",
                                value: "ios-9",
                              },
                              {
                                text: "8",
                                value: "ios-8",
                              },
                            ],
                          },
                        ],
                        customOptions: {
                          label: "ComboBox Option Group",
                          placeholder: "Select an OS",
                        },
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "radioGroup",
                        key: "rcDatePeriod",
                        customOptions: {
                          legend: "Select RC Date Period",
                          name: "rc_date_period",
                        },
                        radioGroup: [
                          {
                            value: "most-recent",
                            label: "Display most recent for all companies",
                          },
                          {
                            value: "custom-date-period",
                            label: "Select custom date period",
                          },
                        ],
                        colspan: 3,
                      },
                      {
                        type: "radioGroup",
                        key: "rcDatePeriod",
                        customOptions: {
                          legend: "Select RC Date Period",
                          name: "rc_date_period",
                        },
                        radioGroup: [
                          {
                            value: "most-recent",
                            label: "Display most recent for all companies",
                          },
                          {
                            value: "custom-date-period",
                            label: "Select custom date period",
                            icon: "info-circle",
                            iconButtonPopoverText:
                              "The comp report will show all the periods for each company within the date range",
                            iconButtonPopoverPosition: ["right-center"],
                          },
                        ],
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "checkboxGroup",
                        key: "rcDatePeriod2",
                        customOptions: {
                          legend: "Select RC Date Period",
                          name: "rc_date_period_2",
                        },
                        checkboxGroup: [
                          {
                            value: "most-recent",
                            label: "Display most recent for all companies",
                          },
                          {
                            value: "custom-date-period",
                            label: "Select custom date period",
                          },
                        ],
                        colspan: 3,
                      },
                      {
                        type: "checkboxGroup",
                        key: "rcDatePeriod_3",
                        customOptions: {
                          legend: "Select RC Date Period",
                          name: "rc_date_period3",
                        },
                        checkboxGroup: [
                          {
                            value: "most-recent",
                            label: "Display most recent for all companies",
                          },
                          {
                            value: "custom-date-period",
                            label: "Select custom date period",
                            icon: "info-circle",
                            iconButtonPopoverText:
                              "The comp report will show all the periods for each company within the date range",
                            iconButtonPopoverPosition: ["right-center"],
                          },
                        ],
                        colspan: 3,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "slot",
                        slotName: "testSlot",
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "Grid",
                        theme: "column-theme",
                        key: "rl_recoveries",
                        multiselection: true,
                        fixedLayout: true,
                        maxWidth: 1050,
                        maxHeight: 300,
                        verticalExpandable: true,
                        requireRowAddRemove: true,
                        requireMinRowsCount: 1,
                        events: [
                          {
                            type: "data-change",
                            extra: "This is extra data",
                            name: "on-rl-recoveries-change",
                          },
                          {
                            type: "row-selection-change",
                            extra: "This is extra data",
                            name: "on-row-selection-change",
                          },
                        ],
                        colConfigs: [
                          {
                            key: "name",
                            label: "Name",
                            dataType: "text",
                            readonly: false,
                            sortable: true,
                            sorted: 1,
                            width: "300px",
                          },
                          {
                            key: "country",
                            label: "Country",
                            dataType: "select",
                            options: [
                              { value: "US", text: "US" },
                              { value: "CA", text: "Canada" },
                              { value: "IND", text: "India" },
                            ],
                            width: "120px",
                          },
                          {
                            key: "nameLink",
                            label: "Link",
                            dataType: "link",
                            readonly: true,
                            sortable: true,
                            sorted: 1,
                            width: "300px",
                            noVisitedStyles: true,
                          },
                          {
                            key: "name",
                            label: "Name",
                            dataType: "text",
                            sortable: true,
                            sorted: 1,
                            width: "200px",
                          },
                          {
                            key: "AAA",
                            label: "AAA",
                            dataType: "percent",
                            precision: 2,
                            readonly: false,
                            sortable: true,
                            sorted: 1,
                            width: "120px",
                          },
                          {
                            key: "AAH",
                            label: "AA(High)",
                            dataType: "currency",
                            precision: 2,
                            readonly: false,
                            headerAlign: "center",
                            width: "120px",
                          },
                          {
                            key: "AA",
                            label: "AA",
                            dataType: "number",
                            precision: 2,
                            readonly: false,
                            headerAlign: "center",
                            width: "120px",
                          },
                          {
                            dataType: "actions",
                            headerAlign: "center",
                            dataAlign: "center",
                            icon: "ellipsis-circle",
                            actionPopoverPosition: [
                              "bottom-center",
                              "top-center",
                              "bottom-left",
                              "top-left",
                            ],
                            actions: [
                              {
                                id: "download",
                                label: "Download",
                                icon: "download",
                                actionEventName: "on-download-click",
                              },
                              {
                                id: "delete",
                                label: "Delete",
                                icon: "trash",
                                actionEventName: "on-delete-click",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "Grid",
                        theme: "column-theme",
                        key: "rl_recoveries2",
                        fixedLayout: true,
                        maxWidth: 700,
                        maxHeight: 300,
                        multiselection: true,
                        verticalExpandable: true,
                        requireRowAddRemove: true,
                        requireMinRowsCount: 1,
                        emptyTableMessage: "No data found",
                        colConfigs: [
                          {
                            key: "name",
                            label: "Name",
                            dataType: "text",
                            readonly: false,
                            sorted: 1,
                            width: "300px",
                          },
                          {
                            key: "AAA",
                            label: "AAA",
                            dataType: "percent",
                            precision: 2,
                            readonly: false,
                            sorted: 1,
                            width: "120px",
                          },
                          {
                            key: "AAH",
                            label: "AA(High)",
                            dataType: "currency",
                            precision: 2,
                            readonly: false,
                            headerAlign: "center",
                            width: "120px",
                          },
                          {
                            key: "AA",
                            label: "AA",
                            dataType: "number",
                            precision: 2,
                            readonly: false,
                            headerAlign: "center",
                            width: "120px",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "pagination",
                        customOptions: {
                          totalItems: 35,
                          showItemsInfo: true,
                          showItemsSelect: true,
                        },
                        events: [
                          {
                            type: "page-change",
                            name: "on-pagination-page-change",
                          },
                        ],
                        colspan: 6,
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "fileUpload",
                        key: "uploadedFile",
                        acceptFileExtensions: [".xlsx", ".xls", ".csv"],
                        customOptions: {
                          text: "Upload a File",
                        },
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "listGroup",
                        key: "activeGroup",
                        listGroup: [
                          {
                            name: "Balance Sheet",
                            active: true,
                            customOptions: {
                              icon: "group-list",
                            },
                          },
                          {
                            name: "Income Statement",
                            slotElement: {
                              type: "button",
                              customOptions: {
                                variation: "icon-only",
                                text: "Button 2",
                                icon: "share",
                                size: "small",
                              },
                              events: [
                                {
                                  type: "click",
                                  extra: "This is extra data",
                                  name: "on-generate-pool-results-click",
                                },
                              ],
                            },
                          },
                          {
                            name: "Cash flow",
                          },
                          {
                            name: "Ratios",
                          },
                        ],
                        events: [
                          {
                            type: "change",
                            name: "on-financials-main-category-change",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "buttonWithPopover",
                        customOptions: {
                          id: "test-popover-2",
                          variation: "icon-only",
                          icon: "info-circle",
                        },
                        popoverPosition: ["right-center"],
                        popoverUiSchema: {
                          type: "input",
                          dataType: "text",
                          key: "popoverInput",
                          customOptions: {
                            readonly: true,
                          },
                        },
                        popoverUiData: {
                          popoverInput: "Lorem ipsum dolor sit amet",
                        },
                      },
                      {
                        type: "buttonWithPopover",
                        customOptions: {
                          id: "test-popover-1",
                          variation: "primary",
                          icon: "open-new",
                          text: "Button 1",
                          iconOnly: true,
                        },
                        showPopoverOnClick: true,
                        popoverUiSchema: {
                          type: "Section",
                          customOptions: {
                            title: "Popover Title",
                            content:
                              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                          },
                        },
                      },
                      {
                        type: "selectionfilter",
                        key: "selectedFilterValue",
                        filterId: "latest-on-multi-select",
                        selectedItem: ["left"],
                        mandatorySelectionObject: {
                          defaultSelectionKey: "",
                          isDefaultSelectionKeyRequired: false,
                        },
                        options: [
                          {
                            label: "Latest on Left",
                            value: "left",
                          },
                          {
                            label: "Latest on Right",
                            value: "right",
                          },
                        ],
                        customOptions: {
                          title: "Latest on",
                          isMultiSelect: true,
                          disabled: false,
                        },
                      },
                    ],
                  },
                  {
                    type: "HorizontalLayout",
                    style: {
                      marginBottom: "16px",
                    },
                    elements: [
                      {
                        type: "buttonGroup",
                        elements: [
                          {
                            type: "button",
                            text: "Button 1",
                            active: true,
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "input",
                                    dataType: "text",
                                    customOptions: {
                                      label: "Text 1",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: "button",
                            text: "Button 2",
                            active: false,
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "input",
                                    dataType: "text",
                                    customOptions: {
                                      label: "Text 2",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: "button",
                            text: "Button 3",
                            disabled: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
                actions: {
                  type: "button-container",
                  customOptions: {},
                  elements: [
                    {
                      type: "button",
                      customOptions: {
                        variation: "primary",
                        text: "Copy",
                      },
                      events: [
                        {
                          type: "click",
                          name: "on-copy",
                        },
                      ],
                    },
                    {
                      type: "button",
                      customOptions: {
                        variation: "secondary",
                        text: "Clear",
                      },
                      events: [
                        {
                          type: "click",
                          name: "on-clear",
                        },
                      ],
                    },
                  ],
                },
                colspan: 9,
              },
            ],
          },
        ],
      },
      uiData: {
        textareaValue: "This is a textarea...",
        country: null,
        toggleModal: false,
        toggleDialog: false,
        triggerAddRow: false,
        modalInput: "I am a modal",
        textInputValue: "Some text",
        numberInputValue: 25.78,
        numberROValue: 176543.78,
        percentInputValue: 6548967946,
        percentROValue: 0.2178,
        currencyInputValue: 1786126.98217,
        currencyROValue: 89873132.132,
        dateInputValue: "2024-01-23",
        dateROValue: "2024-01-23",
        startDate: new Date(2023, 6, 12),
        startTime: null,
        searchedValue: null,
        usState: [],
        usStateRequired: [],
        usStates: [],
        operSys: [],
        rl_recoveries: [
          {
            name: "Senior Secured - Tier 1",
            nameLink: {
              linkText: "Senior Secured - Tier 1",
              href: "/demo1",
            },
            country: "US",
            email_id: "neeraj.punjabi@morningstar.com",
            countryStates: [
              {
                text: "Florida",
                value: "FL",
              },
              {
                text: "Texas",
                value: "TX",
              },
              {
                text: "Alabama",
                value: "AL",
              },
            ],
            A: 0.605,
            AA: 0.54,
            AAA: 0.5075,
            AAH: 0.52375,
            AAL: 0.5617,
            AH: 0.5833,
            AL: 0.6233,
            B: 0.8025,
            BB: 0.74,
            BBB: 0.66,
            BBBH: 0.6417,
            BBBL: 0.6867,
            BBH: 0.7133,
            BBL: 0.7608,
            BH: 0.7817,
            BL: 0.8025,
            CCC: 0.8025,
            CCCH: 0.8025,
            CCCL: 0.8025,
          },
          {
            name: "Senior Secured Cov Lite - Tier 1",
            nameLink: {
              linkText: "Senior Secured Cov Lite - Tier 1",
              href: "/demo2",
            },
            country: "CA",
            email_id: "nilesh.kadam@morningstar.com",
            countryStates: [
              {
                value: "A",
                text: "A",
              },
              {
                value: "B",
                text: "B",
              },
              {
                value: "C",
                text: "C",
              },
            ],
            A: 0.5675,
            AA: 0.5025,
            AAA: 0.4375,
            AAH: 0.47,
            AAL: 0.5242,
            AH: 0.5458,
            AL: 0.5833,
            B: 0.8025,
            BB: 0.74,
            BBB: 0.66,
            BBBH: 0.6417,
            BBBL: 0.6867,
            BBH: 0.7133,
            BBL: 0.7608,
            BH: 0.7817,
            BL: 0.8025,
            CCC: 0.8025,
            CCCH: 0.8025,
            CCCL: 0.8025,
          },
          {
            name: "Second Lien - Tier 1",
            nameLink: {
              linkText: "Second Lien - Tier 1",
              href: "/demo3",
            },
            country: "US",
            email_id: "dave.gilbert@morningstar.com",
            countryStates: [
              {
                text: "Florida",
                value: "FL",
              },
              {
                text: "Texas",
                value: "TX",
              },
              {
                text: "Alabama",
                value: "AL",
              },
            ],
            A: 0.3125,
            AA: 0.3075,
            AAA: 0.285,
            AAH: 0.29625,
            AAL: 0.3092,
            AH: 0.3108,
            AL: 0.315,
            B: 0.365,
            BB: 0.365,
            BBB: 0.32,
            BBBH: 0.3175,
            BBBL: 0.335,
            BBH: 0.35,
            BBL: 0.365,
            BH: 0.365,
            BL: 0.365,
            CCC: 0.365,
            CCCH: 0.365,
            CCCL: 0.365,
          },
          {
            name: "Senior Secured - Tier 2",
            nameLink: {
              linkText: "Second Secured - Tier 2",
              href: "/demo4",
            },
            country: "IND",
            email_id: null,
            countryStates: [
              {
                text: "Maharashtra",
                value: "MH",
              },
              {
                text: "Himachal Pradesh",
                value: "HP",
              },
              {
                text: "Goa",
                value: "GA",
              },
            ],
            A: 0.555,
            AA: 0.49,
            AAA: 0.4575,
            AAH: 0.47375,
            AAL: 0.5117,
            AH: 0.5333,
            AL: 0.5733,
            B: 0.7525,
            BB: 0.69,
            BBB: 0.61,
            BBBH: 0.5917,
            BBBL: 0.6367,
            BBH: 0.6633,
            BBL: 0.7108,
            BH: 0.7317,
            BL: 0.7525,
            CCC: 0.7525,
            CCCH: 0.7525,
            CCCL: 0.7525,
          },
          {
            name: "Senior Secured - Tier 1",
            nameLink: {
              linkText: "Senior Secured - Tier 1",
              href: "/demo1",
            },
            country: "US",
            email_id: "neeraj.punjabi@morningstar.com",
            countryStates: [
              {
                text: "Florida",
                value: "FL",
              },
              {
                text: "Texas",
                value: "TX",
              },
              {
                text: "Alabama",
                value: "AL",
              },
            ],
            A: 0.605,
            AA: 0.54,
            AAA: 0.5075,
            AAH: 0.52375,
            AAL: 0.5617,
            AH: 0.5833,
            AL: 0.6233,
            B: 0.8025,
            BB: 0.74,
            BBB: 0.66,
            BBBH: 0.6417,
            BBBL: 0.6867,
            BBH: 0.7133,
            BBL: 0.7608,
            BH: 0.7817,
            BL: 0.8025,
            CCC: 0.8025,
            CCCH: 0.8025,
            CCCL: 0.8025,
          },
          {
            name: "Senior Secured Cov Lite - Tier 1",
            nameLink: {
              linkText: "Senior Secured Cov Lite - Tier 1",
              href: "/demo2",
            },
            country: "CA",
            email_id: "nilesh.kadam@morningstar.com",
            countryStates: [
              {
                value: "A",
                text: "A",
              },
              {
                value: "B",
                text: "B",
              },
              {
                value: "C",
                text: "C",
              },
            ],
            A: 0.5675,
            AA: 0.5025,
            AAA: 0.4375,
            AAH: 0.47,
            AAL: 0.5242,
            AH: 0.5458,
            AL: 0.5833,
            B: 0.8025,
            BB: 0.74,
            BBB: 0.66,
            BBBH: 0.6417,
            BBBL: 0.6867,
            BBH: 0.7133,
            BBL: 0.7608,
            BH: 0.7817,
            BL: 0.8025,
            CCC: 0.8025,
            CCCH: 0.8025,
            CCCL: 0.8025,
          },
          {
            name: "Second Lien - Tier 1",
            nameLink: {
              linkText: "Second Lien - Tier 1",
              href: "/demo3",
            },
            country: "US",
            email_id: "dave.gilbert@morningstar.com",
            countryStates: [
              {
                text: "Florida",
                value: "FL",
              },
              {
                text: "Texas",
                value: "TX",
              },
              {
                text: "Alabama",
                value: "AL",
              },
            ],
            A: 0.3125,
            AA: 0.3075,
            AAA: 0.285,
            AAH: 0.29625,
            AAL: 0.3092,
            AH: 0.3108,
            AL: 0.315,
            B: 0.365,
            BB: 0.365,
            BBB: 0.32,
            BBBH: 0.3175,
            BBBL: 0.335,
            BBH: 0.35,
            BBL: 0.365,
            BH: 0.365,
            BL: 0.365,
            CCC: 0.365,
            CCCH: 0.365,
            CCCL: 0.365,
          },
          {
            name: "Senior Secured - Tier 2",
            nameLink: {
              linkText: "Second Secured - Tier 2",
              href: "/demo4",
            },
            country: "IND",
            email_id: null,
            countryStates: [
              {
                text: "Maharashtra",
                value: "MH",
              },
              {
                text: "Himachal Pradesh",
                value: "HP",
              },
              {
                text: "Goa",
                value: "GA",
              },
            ],
            A: 0.555,
            AA: 0.49,
            AAA: 0.4575,
            AAH: 0.47375,
            AAL: 0.5117,
            AH: 0.5333,
            AL: 0.5733,
            B: 0.7525,
            BB: 0.69,
            BBB: 0.61,
            BBBH: 0.5917,
            BBBL: 0.6367,
            BBH: 0.6633,
            BBL: 0.7108,
            BH: 0.7317,
            BL: 0.7525,
            CCC: 0.7525,
            CCCH: 0.7525,
            CCCL: 0.7525,
          },
          {
            name: "Senior Secured - Tier 1",
            nameLink: {
              linkText: "Senior Secured - Tier 1",
              href: "/demo1",
            },
            country: "US",
            email_id: "neeraj.punjabi@morningstar.com",
            countryStates: [
              {
                text: "Florida",
                value: "FL",
              },
              {
                text: "Texas",
                value: "TX",
              },
              {
                text: "Alabama",
                value: "AL",
              },
            ],
            A: 0.605,
            AA: 0.54,
            AAA: 0.5075,
            AAH: 0.52375,
            AAL: 0.5617,
            AH: 0.5833,
            AL: 0.6233,
            B: 0.8025,
            BB: 0.74,
            BBB: 0.66,
            BBBH: 0.6417,
            BBBL: 0.6867,
            BBH: 0.7133,
            BBL: 0.7608,
            BH: 0.7817,
            BL: 0.8025,
            CCC: 0.8025,
            CCCH: 0.8025,
            CCCL: 0.8025,
          },
          {
            name: "Senior Secured Cov Lite - Tier 1",
            nameLink: {
              linkText: "Senior Secured Cov Lite - Tier 1",
              href: "/demo2",
            },
            country: "CA",
            email_id: "nilesh.kadam@morningstar.com",
            countryStates: [
              {
                value: "A",
                text: "A",
              },
              {
                value: "B",
                text: "B",
              },
              {
                value: "C",
                text: "C",
              },
            ],
            A: 0.5675,
            AA: 0.5025,
            AAA: 0.4375,
            AAH: 0.47,
            AAL: 0.5242,
            AH: 0.5458,
            AL: 0.5833,
            B: 0.8025,
            BB: 0.74,
            BBB: 0.66,
            BBBH: 0.6417,
            BBBL: 0.6867,
            BBH: 0.7133,
            BBL: 0.7608,
            BH: 0.7817,
            BL: 0.8025,
            CCC: 0.8025,
            CCCH: 0.8025,
            CCCL: 0.8025,
          },
          {
            name: "Second Lien - Tier 1",
            nameLink: {
              linkText: "Second Lien - Tier 1",
              href: "/demo3",
            },
            country: "US",
            email_id: "dave.gilbert@morningstar.com",
            countryStates: [
              {
                text: "Florida",
                value: "FL",
              },
              {
                text: "Texas",
                value: "TX",
              },
              {
                text: "Alabama",
                value: "AL",
              },
            ],
            A: 0.3125,
            AA: 0.3075,
            AAA: 0.285,
            AAH: 0.29625,
            AAL: 0.3092,
            AH: 0.3108,
            AL: 0.315,
            B: 0.365,
            BB: 0.365,
            BBB: 0.32,
            BBBH: 0.3175,
            BBBL: 0.335,
            BBH: 0.35,
            BBL: 0.365,
            BH: 0.365,
            BL: 0.365,
            CCC: 0.365,
            CCCH: 0.365,
            CCCL: 0.365,
          },
          {
            name: "Senior Secured - Tier 2",
            nameLink: {
              linkText: "Second Secured - Tier 2",
              href: "/demo4",
            },
            country: "IND",
            email_id: null,
            countryStates: [
              {
                text: "Maharashtra",
                value: "MH",
              },
              {
                text: "Himachal Pradesh",
                value: "HP",
              },
              {
                text: "Goa",
                value: "GA",
              },
            ],
            A: 0.555,
            AA: 0.49,
            AAA: 0.4575,
            AAH: 0.47375,
            AAL: 0.5117,
            AH: 0.5333,
            AL: 0.5733,
            B: 0.7525,
            BB: 0.69,
            BBB: 0.61,
            BBBH: 0.5917,
            BBBL: 0.6367,
            BBH: 0.6633,
            BBL: 0.7108,
            BH: 0.7317,
            BL: 0.7525,
            CCC: 0.7525,
            CCCH: 0.7525,
            CCCL: 0.7525,
          },
        ],
        rl_recoveries2: [],
        uploadedFile: null,
        selectedFilterValue: [],
        activeGroup: null,
      },
    };
  },
  mounted() {
    EventBus.$on(
      "on-generate-pool-results-click",
      this.onGeneratePoolResultsClick,
    );
    EventBus.$on(
      "on-generate-pool-results-click-2",
      this.onGeneratePoolResultsClick2,
    );
    EventBus.$on(
      "on-generate-pool-results-click-3",
      this.onGeneratePoolResultsClick3,
    );
    EventBus.$on("on-row-selection-change", this.onRowSelectionChange);
    EventBus.$on("on-download-click", this.onDownloadClick);
    EventBus.$on("on-delete-click", this.onDeleteClick);
    EventBus.$on("on-pagination-page-change", this.onPaginationPageChange);
    EventBus.$on("on-copy", this.onCopy);
    EventBus.$on("on-close", this.onClose);
    EventBus.$on("on-dialog-cancel", this.onDialogCancel);
    EventBus.$on(
      "on-financials-main-category-change",
      this.onFinancialsMainCategoryChange,
    );
  },
  methods: {
    onCopy() {
      // console.log("copy was clicked...");
    },
    onClose() {
      this.uiData.toggleModal = false;
    },
    onGeneratePoolResultsClick() {
      
      const allErrors = this.$refs.screenDesigner.checkErrors();
      allErrors.then(() => {
        // console.log(res);
      });
    },
    onGeneratePoolResultsClick2() {
      this.uiData.toggleModal = true;
    },
    onGeneratePoolResultsClick3() {
      this.uiData.toggleDialog = true;
    },
    onDialogCancel() {
      this.uiData.toggleDialog = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
