import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { axiosInit } from "@/axios-interceptor";
import "./main.scss";
axiosInit();
const app = createApp(App);

app.use(router);

app.mount("#app");
