const urlWithProtocol = new RegExp('^https?://');

/**
 * @returns {string} URL to MRE host, protocol://host.domain:port.
 *          For example: http://localhost:4567.
 */
export function getAPIHost() {
  return getApiHostUrl(process.env,'VUE_APP_CIAUDIT_HOST');
}
/**
 * For testing purposes only.
 * @param {Object} env Environment object.
 * @param {string} hostname Name of host environment variable.
 * @returns {string} URL to host, including http protocol (default https).
 */

function getApiHostUrl(env, hostname) {
  if (env[hostname]) {
    let host = env[hostname];
    if (!urlWithProtocol.test(host)) {
      host = 'https://' + host;
    }
    return host;
  } else {
    throw new Error(`Missing environment variable: ${hostname}`);
  }
}
