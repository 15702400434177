import axios from 'axios';
import { redirectToCreditLogin } from '@/userAuthentication';

const identity = o => o;

// cookie send IMP
axios.defaults.withCredentials = true;

export function axiosInit() {
  axios.interceptors.request.use(identity, error => Promise.reject(error));
  axios.interceptors.response.use(identity, handleResponseError);
}

function handleResponseError(error) {
  if (error?.response?.status === 401) {
    redirectToCreditLogin(new URL(window.location.href));
  }
  return Promise.reject(error);
}