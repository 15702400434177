// import Vue from 'vue';
// import { VueRouter } from 'vue-router';
import { createWebHistory, createRouter } from "vue-router";
import { routes } from './routes';
import { redirectToCreditLogin, validateUimCookie } from '@/userAuthentication';

// Vue.use(VueRouter);

const authCacheMillis = 60000;
let authCheckTime = 0;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next)=> {
  // Make sure that the `next` function is called exactly once in any given pass through the
  // navigation guard. It can appear more than once, but only if the logical paths have no
  // overlap, otherwise the hook will never be resolved or produce errors.
  if (Date.now() - authCheckTime <= authCacheMillis) {
    next();
  } else {
    const routeRequiresAuth = doesRouteRequireAuth(to.matched);
    if (!routeRequiresAuth) {
      next();
    } else {
      const successUrl = new URL(window.location.href);
      validateUimCookie().then(resp => {
        // && resp.authorize
        if (resp) {
          authCheckTime = Date.now();
          next();
          // resp.message.isAdmin = false
          localStorage.setItem("isAdmin",resp.message?.isAdmin)
        } else {
          redirectToCreditLogin(successUrl);
          next(false);
        }
      });
    }
  }
})

// router.beforeEach((to, from, next) => beforeEach(to, from, next));

export function doesRouteRequireAuth(matched) {
  // we assume all routes require auth unless explicitly set to false
  return !matched.some(
    route => route.meta && route.meta.requiresAuth === false,
  );
}

export default router;
