import axios from "axios";
import { getAPIHost } from "./apiHost";

const axiosInstance = axios.create({
  withCredentials: true
});

export function registerUser(payload) {
  return axiosInstance.post(`${getAPIHost()}/register`,payload);
}
export function updateEmployees(payload) {
  return axiosInstance.put(`${getAPIHost()}/update/employees`,payload)
}
export function getEmployees(email) {
  let url = "";
  if(email){
    url = `${getAPIHost()}/employees?email_id=${email}`
  }else{
    url = `${getAPIHost()}/employees`
  }
  return axiosInstance.get(url);
}
export function getManagersAndTechLead() {
  let url = `${getAPIHost()}/managerTechlead`
  return axiosInstance.get(url);
}