import axios from "axios";
import { getAPIHost } from './apis/apiHost';
// import { getMreHost } from "@/apis/mre";
// import Logger from "js-logger";

//const logger = Logger.get("userauthentication");
const urlWithProtocol = new RegExp("^https?://");

/**
 * All user  authentication / security api and methods goes here
 * More on UIM : https://mswiki.morningstar.com/display/PDS/UIM%3A+Access+Management+%28OpenAM%29+APIs
 */

export const LOGIN_URL = process.env.VUE_APP_LOGIN_URL;

// const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export function getUimHost() {
  return getUimUrl(process.env);
}

function getUimUrl(env) {
  if (env.VUE_APP_UIM_HOST) {
    let host = env.VUE_APP_UIM_HOST;
    if (!urlWithProtocol.test(host)) {
      host = "https://" + host;
    }
    return host;
  } else {
    throw new Error("Missing environment variable.");
  }
}

/**
 * Validate UIM cookie
 */
export function validateUimCookie() {
  // const url = `${BASE_URL}/validate`;
  const url = `${getAPIHost()}/validate`;
  return axios
    .get(url)
    .then(resp => resp.data)
    .catch(err => {
      console.error('failed to authenticate sso token; expect more errors', err);
      // assume the best: token is valid.
      return true;
    });
}

/**
 * Logout
 */
export function logoutUim() {
  const location = window.location;
  const logoutUrl = new URL(getUimHost() + '/sso/json/logout-browser');
  logoutUrl.searchParams.append(
    'returnTo',
    `${location.protocol}//${location.host}/`
  );
  location.href = logoutUrl;
}
let redirected = false;

/**
 * Redirect a user to the external Credit Login page.
 * Redirects using window.location.href and operates outside the scope of the Vue Router.
 * @param {URL} successUrl - A URL for the Credit Login page to redirect to upon success.
 */

export function redirectToCreditLogin(successUrl) {
  if (redirected) {
    return;
  }
  if (!successUrl) {
    throw new Error('successUrl is required.');
  }
  if (!(successUrl instanceof URL)) {
    throw new Error('successUrl should be an instance of URL.');
  }
  const loginUrl = new URL(LOGIN_URL);
  loginUrl.searchParams.append('targetUrl', successUrl);
  window.location.href = loginUrl;
  redirected = true;
}