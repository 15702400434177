// import { computed } from '@vue/composition-api';
import { computed } from 'vue'
import useVuelidate from '@vuelidate/core';

export default (rules, data) => {
  const formRules = computed(() => {
    return {
      ...rules,
    };
  });
  const v$ = useVuelidate(formRules, data);
  return { v$ };
};

export const globalUseValidate = () => {
  return useVuelidate();
};
